import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  PreviousFinishingSubphaseCommandService,
  NextFinishingSubphaseCommandService,
  ConfirmToolCheckoutCommand
} from '@app/modules/finish-phase/commands';
import { SetupFooterService } from '@app/shared/components';
import { nav } from '@app/shared/utils';
import { Subscription } from 'rxjs';
import { FinishPhaseService } from '@app/modules/finish-phase/services';
import { ActiveWorkCenterService } from '@app/core/workcenter';
import { RunSubPhaseType, ToolCheckoutInfo, SetToolCheckoutResultEntry, ToolCheckStatus } from 'chronos-core-client';

@Component({
  selector: 'app-tool-checkout',
  templateUrl: './tool-checkout.component.html',
  styleUrls: ['./tool-checkout.component.scss']
})
export class ToolCheckoutComponent implements OnInit, OnDestroy {
  public toolCheckoutInfo: ToolCheckoutInfo[];
  public freeButtonYes = null;
  public freeButtonNo = null;
  public navigation = nav;
  private subscriptions = new Subscription();

  private backCommand: PreviousFinishingSubphaseCommandService;
  private nextCommand: NextFinishingSubphaseCommandService;
  private confirmCheckboxCommand: ConfirmToolCheckoutCommand;

  constructor(
    private finishPhaseService: FinishPhaseService,
    private setupFooterService: SetupFooterService,
    private activeWorkCenterService: ActiveWorkCenterService
  ) {}

  public ngOnInit(): void {
    this.setCommands();

    this.subscriptions.add(
      this.finishPhaseService.getProductionOrderToolCheckoutInfo().subscribe((toolCheckoutInfo) => {
        this.toolCheckoutInfo = toolCheckoutInfo;
      })
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public onCommentBlur(comment: string, externalArticleId: string): void {
    const index = this.toolCheckoutInfo.findIndex((x) => x.externalArticleId === externalArticleId);
    const checkResult = this.toolCheckoutInfo[index].checkResult;
    this.saveToolQualityControl(index, comment, checkResult);
  }

  public containsForwardedWaste(checkResult: ToolCheckStatus, externalArticleId: string): void {
    const index = this.toolCheckoutInfo.findIndex((x) => x.externalArticleId === externalArticleId);
    const comment = this.toolCheckoutInfo[index].comment;
    this.saveToolQualityControl(index, comment, checkResult);
  }

  public getClassByEnumValue(checkResult: ToolCheckStatus, isFreeForProduction: boolean): string {
    switch (checkResult) {
      case ToolCheckStatus.OK:
        return isFreeForProduction ? 'result-select-button__item--ok-active' : '';
      case ToolCheckStatus.NOK:
        return !isFreeForProduction ? 'result-select-button__item--nok-active' : '';
      default:
        return '';
    }
  }

  private setCommands(): void {
    this.backCommand = new PreviousFinishingSubphaseCommandService(this.setupFooterService);
    this.backCommand.setNavigationParams(nav.routes.finishOverview);

    this.nextCommand = new NextFinishingSubphaseCommandService(this.setupFooterService);
    this.nextCommand.setNavigationParams(RunSubPhaseType.FINISHING_END, nav.routes.finishEnd);

    this.confirmCheckboxCommand = new ConfirmToolCheckoutCommand(this.finishPhaseService);
    this.confirmCheckboxCommand.buttonText = 'FINISH_PHASE.KPI_CONFIRMATION';
    this.finishPhaseService.isQaToolCheckoutConfirmed().subscribe((value) => {
      this.confirmCheckboxCommand.enabled = value;
      this.confirmCheckboxCommand.required = !value;
      this.nextCommand.enabled = value;
    });

    this.finishPhaseService.setCommands(this.backCommand, this.nextCommand, this.confirmCheckboxCommand);
  }

  private saveToolQualityControl(index: number, comment: string, checkResult: ToolCheckStatus): void {
    const toolCheckout = this.toolCheckoutInfo[index];
    const param: SetToolCheckoutResultEntry = {
      comment,
      containerId: toolCheckout.containerId,
      checkResult,
      runId: toolCheckout.runId,
      workCenterId: this.activeWorkCenterService.getWorkCenterId()
    };

    this.finishPhaseService.setToolCheckoutResult(param).subscribe((result) => {
      if (result) {
        this.toolCheckoutInfo[index].comment = comment;
        this.toolCheckoutInfo[index].checkResult = checkResult;
      }
    });
  }
}
