import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CollapseExpandNotificationService, RunPhaseService } from '@app/modules/run-phase/services';
import { nav } from '@app/shared/utils';
import { OutputPalletsService } from '@app/modules/run-phase/state/output-pallets/output-pallets.service';
import { Subscription } from 'rxjs';
import { Quantity } from 'chronos-core-client';
import { ActiveOrderQuery } from '@app/core/global-state';

@Component({
  selector: 'app-run-phase',
  templateUrl: './run-phase.component.html',
  styleUrls: ['./run-phase.component.scss']
})
export class RunPhaseComponent implements OnInit, OnDestroy {
  public counter: Quantity;
  public inputPalletsExpanded = false;
  public outputPalletsExpanded = false;

  private subscriptions = new Subscription();

  @HostListener('window:keydown.enter', ['$event'])
  public redirectToMounting(event: KeyboardEvent) {
    const arr = ['INPUT', 'SELECT', 'TEXTAREA', 'BUTTON', 'OPTION', 'OPTGROUP'];

    const element = event.target as HTMLElement;
    if (arr.indexOf(element.tagName) === -1) {
      this.router.navigate([nav.routes.mountingPrimary]);
    }
  }

  constructor(
    private runPhaseService: RunPhaseService,
    private outputPalletsService: OutputPalletsService,
    private collapseExpandNotificationService: CollapseExpandNotificationService,
    private router: Router,
    private activeOrderQuery: ActiveOrderQuery
  ) {}

  public ngOnInit(): void {
    this.runPhaseService.getMachineCounterFromStore().subscribe((counterValue) => {
      this.counter = { unitId: this.activeOrderQuery.getProductionOrderUnitId(), value: counterValue };
    });

    this.subscriptions.add(this.runPhaseService.getCounterNotifications().subscribe());
    this.subscriptions.add(this.runPhaseService.getQuantityPoolWithNotifications().subscribe());
    this.runPhaseService.renewMachineCounter();
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public filterOutputContainersList(filter: string): void {
    this.outputPalletsService.updateFilter(filter);
  }

  public expandInputPallets(): void {
    this.inputPalletsExpanded = !this.inputPalletsExpanded;
    this.collapseExpandNotificationService.emitInput(this.inputPalletsExpanded);
  }

  public expandOutputPallets(): void {
    this.outputPalletsExpanded = !this.outputPalletsExpanded;
    this.collapseExpandNotificationService.emitOutput(this.outputPalletsExpanded);
  }
}
